import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import myTheme from "../theme"

import Layout from "../templates/main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import ContactSection from "../components/Organisms/ContactSection"

const Contato = () => {
  const {
    mainBanner: {
      childImageSharp: { fluid: mainBanner },
    }
  } = useStaticQuery(
    graphql`
      query {
        mainBanner: file(relativePath: { eq: "banners/main-banner.jpg" }) {
          childImageSharp {
            fluid(base64Width: 1000, quality: 50) {
              base64
            }
          }
        }
      }
    `
  )
  return (
    <Layout>
      <SEO title="Entre em contato" />

      <Hero
        title="Entre em Contato."
        description="Conte-nos sobre seu projeto!"
        fluidImage={mainBanner}
      />

      <ContactSection
        bgColor={myTheme.colors.secondaryBG}
        color="black"
        contactButtonColor="default"
        phoneNumberLink="tel:027996433420"
        whatsappNumberLink="https://api.whatsapp.com/send?phone=+5527996433420&text=Ol%C3%A1.%20Eu%20tenho%20interesse%20nos%20Servi%C3%A7os%20da%20Ag%C3%AAncia."
      />
    </Layout>
  )
}

export default Contato
